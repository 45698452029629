<script setup lang="ts">
import OverlayPanel from 'primevue/overlaypanel'
import type { PropType } from 'vue'

const props = defineProps({
  width: {
    type: String as PropType<'auto' | 'fit'>,
    default: 'fit',
  },
})

defineEmits(['show', 'hide'])

// PANEL STYLE
const dropdownEl = ref<HTMLElement>(null!)
const { width: dropdownWidth } = useElementSize(dropdownEl)
const dropdownPanelStyle = computed(() => ({
  width: props.width === 'fit' ? (dropdownWidth.value < 100 ? 'auto' : `${dropdownWidth.value}px`) : 'auto',
}))

// TOGGLE
const dropdownPanelRef = ref<OverlayPanel>(null!)
const toggleDropdown = (event: Event) => {
  dropdownPanelRef.value.toggle(event)
}
const hideDropdown = () => {
  dropdownPanelRef.value.hide()
}
const showDropdown = (event: Event) => {
  dropdownPanelRef.value.show(event)
}

defineExpose({
  toggle: toggleDropdown,
  hide: hideDropdown,
  show: showDropdown,
})
</script>

<template>
  <div ref="dropdownEl" class="relative inline-block text-left">
    <div aria-expanded="true" aria-haspopup="true" class="cursor-pointer" @click="toggleDropdown">
      <slot name="button" />
    </div>

    <OverlayPanel
      ref="dropdownPanelRef"
      class="l-dropdown-panel"
      :style="dropdownPanelStyle"
      @show="$emit('show', $event)"
      @hide="$emit('hide', $event)"
    >
      <slot name="menu" />
    </OverlayPanel>
  </div>
</template>

<style lang="postcss">
/* Couldn't make it work in scoped */
.l-dropdown-panel {
  @apply rounded-lg border border-neutral-100 bg-white;
}

.l-dropdown-panel .p-overlaypanel-content {
  @apply p-0;
}

.l-dropdown-panel .p-overlaypanel-content:has(> .l-dropdown-section) {
  @apply divide-y divide-gray-100;
}

.l-dropdown-panel .p-overlaypanel-content:not(:has(> .l-dropdown-section)) {
  @apply py-2;
}
</style>
