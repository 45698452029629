<script setup lang="ts">
import type { PropType } from 'vue'
import type { LDropdownItemPropTo, LDropdownItemPropIcon } from './types'

import { icons } from '@ui/constants/icons'

const props = defineProps({
  to: {
    type: String as PropType<LDropdownItemPropTo>,
    default: null,
  },
  /**
   * any l-icon
   */
  icon: {
    type: String as PropType<LDropdownItemPropIcon>,
    default: null,
    validator: validateArray<LDropdownItemPropIcon>(icons as LDropdownItemPropIcon[]),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['click'])

const hasLink = computed<boolean>(() => Boolean(props.to))
const hasIcon = computed<boolean>(() => Boolean(props.icon))
</script>

<template>
  <NuxtLink
    v-if="hasLink"
    :to="to"
    role="menuitem"
    tabindex="-1"
    class="l-dropdown-item text-roxo-liti-500 block w-full cursor-pointer px-6 py-3 text-left text-base font-normal transition duration-100 hover:bg-gray-100"
    :class="{
      'pointer-events-none cursor-not-allowed opacity-50 hover:bg-white': disabled,
    }"
    @click="$emit('click', $event)"
  >
    <slot />
  </NuxtLink>
  <button
    v-else
    type="button"
    role="menuitem"
    tabindex="-1"
    class="l-dropdown-item text-roxo-liti-500 block w-full cursor-pointer px-6 py-3 text-left text-base font-normal transition duration-100 hover:bg-gray-100"
    :class="{
      'pointer-events-none cursor-not-allowed opacity-50 hover:bg-white': disabled,
    }"
    @click="$emit('click', $event)"
  >
    <!-- Icon -->
    <span v-if="hasIcon" class="inline-flex items-center" :class="{ 'mr-5': $slots.default }">
      <LIcon :icon="icon" />
    </span>

    <span v-if="$slots.default">
      <slot />
    </span>
  </button>
</template>
